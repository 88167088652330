import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Maps from '../../../Components/utils/GoogleMaps';

const DEFAULT_CENTER = { lat: 26.8954906, lng: 75.839768 };
const DEFAULT_ZOOM = 15;
const MAP_API_KEY = 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8';

const CollectionMap = () => {

    const location = useLocation().state;
    const [mapInstance, setMapInstance] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [boundryData, setboundryData] = useState([])
    const [areaData, setAreaData] = useState([])
    const [routeData, setRouteData] = useState([])
    const [pathData, setPathData] = useState([])
    const [truckMarker, setTruckMarker] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [animationIntervalId, setAnimationIntervalId] = useState(null);

    // Truck Icon 
    const getTruckIconUrl = (heading = 0) => {
        const color = '#4CAF50';
        const iconSvg = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200" width="100" height="200" transform="rotate(${heading})">
            <rect x="10" y="40" width="80" height="120" fill="${color}" stroke="black" stroke-width="2" />
            <rect x="20" y="10" width="60" height="30" fill="#333" stroke="black" stroke-width="2"/>
            <circle cx="20" cy="170" r="10" fill="black" />
            <circle cx="80" cy="170" r="10" fill="black" />
          </svg>`;
        return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`;
    };

    // Set Truck Icon on map 
    useEffect(() => {
        if (mapInstance && googleMaps && routeData.length > 0) {
            if (!truckMarker) {
                //creating a  marker on map 
                const marker = new googleMaps.Marker({
                    position: { lat: routeData[0].x, lng: routeData[0].y },
                    map: mapInstance,
                    icon: {
                        url: getTruckIconUrl(),
                        scaledSize: new googleMaps.Size(40, 40),
                        anchor: new googleMaps.Point(20, 20),
                    },
                });
                setTruckMarker(marker);
            }
        }
    }, [routeData, mapInstance, googleMaps, truckMarker]);


    //truck animation functionality
    const animateTruck = () => {
        if (!isAnimating && truckMarker && routeData.length > 0) {
            let index = 0;
            const totalPoints = routeData.length;

            const intervalId = setInterval(() => {
                if (index < totalPoints) {
                    const currentPoint = routeData[index];
                    const nextPoint = routeData[index + 1] || currentPoint;

                    const latLng = new googleMaps.LatLng(currentPoint.x, currentPoint.y);
                    const heading = googleMaps.geometry.spherical.computeHeading(
                        new googleMaps.LatLng(currentPoint.x, currentPoint.y),
                        new googleMaps.LatLng(nextPoint.x, nextPoint.y)
                    );

                    truckMarker.setPosition(latLng);         //change position of exiting marker
                    truckMarker.setIcon({                   //change icon  of exiting marker
                        url: getTruckIconUrl(heading),
                        scaledSize: new googleMaps.Size(40, 40),
                        anchor: new googleMaps.Point(20, 20),
                    });

                    index += 2;
                } else {
                    clearInterval(intervalId);
                    setIsAnimating(false);
                    setAnimationIntervalId(null);
                }
            }, 500);
            setAnimationIntervalId(intervalId);
        }
    };

    //stop or start marker animating
    const handleAnimate = () => {
        if (isAnimating) {
            clearInterval(animationIntervalId);
            setAnimationIntervalId(null);
        } else {
            animateTruck();
        }
        setIsAnimating(!isAnimating);
    }

    // UseEffect to get boundry, area, route & path data from API 
    useEffect(() => {
        const getData = async () => {
            try {
                let result = await fetch(`https://apis.ecowrap.in/b2g/community-admin/collections/route/${location.assignment_id}?boundary=true&area=true&route=true&path=true`, {
                    headers: {
                        authorization: `bearer ${(localStorage.getItem("token"))}`
                    }
                });
                result = await result.json();
                if (result.statusCode === 200) {
                    setboundryData(result?.data[0]?.boundaryData || []);
                    setAreaData(result?.data[0]?.areaData || []);
                    setRouteData(result?.data[0]?.routeData || []);
                    setPathData(result?.data[0]?.pathData || []);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [location?.assignment_id]);

    // UseEffect to set boundry, area, route & path data on MAP VIEW
    useEffect(() => {
        const polygons = [];
        const polylines = [];

        if (mapInstance && googleMaps) {
            // Create and store polygons 
            const polygonCoordinates = boundryData.map((location) => ({
                lat: location.x,
                lng: location.y
            }));

            const polygon = new googleMaps.Polygon({
                paths: polygonCoordinates,
                strokeColor: '#808080',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillColor: '#808080',
                fillOpacity: 0.0,
            });

            polygon.setMap(mapInstance);
            polygons.push(polygon);

            const polygonCoordinates2 = areaData.map((location) => ({
                lat: location.x,
                lng: location.y
            }));

            const polygon2 = new googleMaps.Polygon({
                paths: polygonCoordinates2,
                strokeColor: '#808080',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillColor: '#808080',
                fillOpacity: 0.0,
            });

            polygon2.setMap(mapInstance);
            polygons.push(polygon2);

            // Create and store polylines 
            const polylineCoordinates2 = pathData.map((location) => ({
                lat: location.x,
                lng: location.y
            }));
            const polyline2 = new googleMaps.Polyline({
                path: polylineCoordinates2,
                strokeColor: '#f00',
                strokeOpacity: 1.0,
                strokeWeight: 3,
            });

            polyline2.setMap(mapInstance);
            polylines.push(polyline2);


            const polylineCoordinates = routeData.map((cord) => ({
                lat: cord?.x,
                lng: cord?.y
            }));

            const polyline = new googleMaps.Polyline({
                path: polylineCoordinates,
                strokeColor: '#53AF5C',
                strokeOpacity: 1.0,
                strokeWeight: 4,
            });

            polyline.setMap(mapInstance);
            polylines.push(polyline);



        }

        // Cleanup function to remove polygons and polylines
        return () => {
            polygons.forEach((polygon) => {
                polygon.setMap(null);
            });

            polylines.forEach((polyline) => {
                polyline.setMap(null);
            });
        };
    }, [mapInstance, googleMaps, areaData, boundryData, pathData, routeData]);



    return (

        <div className="container-fluid">

            <div className="row mt-4 ">
                <div className='text-end '>
                    <button className=' btn btn-success position-fixed ' style={{ right: '90px', zIndex: 3, width: '140px' }} onClick={handleAnimate}>{isAnimating ? "Stop " : "Start "}Animation</button>
                </div>
                <div className="col-md-12" style={{ height: '80vh', width: '100%' }}>
                    {boundryData && areaData &&
                        <Maps MAP_API_KEY={MAP_API_KEY} DEFAULT_CENTER={DEFAULT_CENTER} DEFAULT_ZOOM={DEFAULT_ZOOM} mapCenter={""} setGoogleMaps={setGoogleMaps} setMapInstance={setMapInstance} />
                    }
                </div>
            </div>

        </div>
    );
};

export default CollectionMap;
