import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Authentication/authSlice';
import { apiSlice } from '../Features/api/apiSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});
