import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../Features/api/apiSlice";

const profileAdaptor = createEntityAdapter({
    selectId: (entity) => entity.ca_id,
})

const initialProfile = profileAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProfile: builder.query({
            query: () => `/authentication/profile`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return profileAdaptor.setAll(initialProfile, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'Profile', id: "List" },
                        ...result.ids.map(id => ({ type: 'Profile', id }))
                    ]
                }
            }
        }),

    })
})


export const {
    useGetProfileQuery,
} = extendedApiSlice

