import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://apis.ecowrap.in/b2g/community-admin`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            } else {
                console.warn("Token is not available. Requests may fail.");
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({}), // Define endpoints here    
});
