import { createEntityAdapter } from "@reduxjs/toolkit"; 
import { apiSlice } from "../../Features/api/apiSlice";

const collectionDateAdaptor = createEntityAdapter({
    selectId: (entity) => entity.assignment_id,
});

const initialCollectionDate = collectionDateAdaptor.getInitialState();


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCollection: builder.query({
            query: ({ page }) => `/collections?page=${page}`,
            transformResponse: (responseData) => {
                const { totalRecords, totalPages, data } = responseData?.data[0] || {};
                return { totalRecords, totalPages, entities: data };
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'Collection', id: "List" },
                        ...results.ids.map(id => ({ type: 'Collection', id })),
                    ];
                }
                return [];
            },
        }),
        getCollectionDate: builder.query({
            query: ({ date }) => `/collections/${date}`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return collectionDateAdaptor.setAll(initialCollectionDate, responseData?.data)
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'CollectionDate', id: "List" },
                        ...results.ids.map(id => ({ type: 'CollectionDate', id })),
                    ];
                }
                return [];
            },
        }),


    }),
});

export const {
    useGetCollectionQuery,
    useGetCollectionDateQuery
} = extendedApiSlice;
