import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
    const [credentials, setCredentials] = useState({ email_id: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const { loginError, loginStatus } = useSelector(state => state.auth);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };
    const handleLogin = (e) => {
        e.preventDefault();
        localStorage.removeItem('token')
        dispatch(login({ email: credentials.email_id, password: credentials.password }));
    };

    const changeCredentials = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    return (
        <div className="background">
            <div className="container vh-100 d-flex justify-content-center align-items-center">
                <form className="form " onSubmit={handleLogin}>
                    <div className="mb-3">
                        <img
                            className="navbar-brand"
                            src="assets/images/ecowrap-logo.png"
                            alt="ecowrap-logo"
                            style={{ width: "200px" }}
                        />
                    </div>
                    <label>Email</label>
                    <input className="form-control"
                        type="text"
                        name="email_id"
                        value={credentials.email_id}
                        onChange={changeCredentials}
                        required
                    />
                    <label className="mt-3">Password</label>
                    <input
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={credentials.password}
                        onChange={changeCredentials}
                        required
                        placeholder="Enter your password"
                        style={{ paddingRight: "40px" }}
                    />
                    <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                            position: "relative",
                            top: "-45px",
                            left: "238px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#6c757d",
                        }}
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    <button className="btn btn-success mt-3" type="submit" disabled={loginStatus === 'pending'}>
                        {loginStatus === 'pending' ? 'Logging in...' : 'Login'}
                    </button>
                    {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
                </form>
            </div>
        </div>
    );
};

export default Login;
