import React from "react";
import './SpinnerForPage.css'

const Spinner = () => {
    return (
        <>
            <div className="loader_home">
                <div className="loader"></div>
            </div>
        </>
    )
}

export default Spinner;