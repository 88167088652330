import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import CollectionDetailsExcerpt from './CollectionDetailsExcerpt';
import Spinner from '../../../Components/Spinner/SpinnerForTable';
import { useGetCollectionDateQuery } from '../CollectionSlice';

const CollectionDetails = () => {
    const location = useLocation();
    const date = location.state.collection_date;
    const [areaFilter, setAreaFilter] = useState([])


    const {
        data: collectionDate,
        isLoading
    } = useGetCollectionDateQuery({ date })

    let filter = []
    filter = collectionDate?.ids?.filter((id) => {
        const data = collectionDate.entities[id];
        return data.area_name === areaFilter
    })

    return (
        <>
            {isLoading && <Spinner />}
            {collectionDate &&
                <div className='collheight d-flex  flex-column pt-4 '>
                    <div className='text-start'>
                        <p> Collection Date : {location.state.collection_date}</p>
                        <div className='d-flex justify-content-around flex-wrap gap-3 my-3'>
                            <button onClick={() => setAreaFilter('93 Jaipur Area I')} className='card btn text-dark btn-secondary border-0'> <b>93 AREA I</b></button>
                            <button onClick={() => setAreaFilter('93 Jaipur Area II')} className=' card btn text-dark btn-secondary border-0'> <b>93 AREA II</b></button>
                            <button onClick={() => setAreaFilter('93 Jaipur Area III')} className='card btn text-dark btn-secondary border-0'>  <b>93 AREA III</b></button>

                        </div>
                    </div>
                    <div className='h-100 ' style={{ overflow: 'hidden scroll' }}>
                        {filter?.map((id, i) => {
                            return <CollectionDetailsExcerpt key={i} id={id} date={date} />
                        })}
                        {filter.length < 1 && collectionDate?.ids?.map((id, i) => {
                            return <CollectionDetailsExcerpt key={i} id={id} date={date} />
                        })}
                    </div>
                </div>
            }
        </>
    )
}

export default CollectionDetails