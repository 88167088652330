import React, { useState } from 'react'
import { useChangePasswordMutation } from './chnagePasswordSlice'

const ChangePassword = () => {

    const [changePasswod, setChangePasswod] = useState({})
    const [changePasswordSlice] = useChangePasswordMutation()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (changePasswod.newPassword !== changePasswod.confirmNewPassword) {
                alert('Passwords do not match')
                return
            }
            await changePasswordSlice({ old_password: changePasswod.oldPassword, new_password: changePasswod.newPassword }).unwrap()
            setChangePasswod({ oldPassword: "", newPassword: "" });
            alert('Password is Changed ')
        }
        catch (e) {
            console.error(e)
        }
    }

    const changehandle = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setChangePasswod({ ...changePasswod, [name]: value })
    }

    return (

        <div className='passwordMain d-flex justify-content-center' style={{ color: "black" }}>
            <form onSubmit={handleSubmit} className='form w-50 mt-5'>
                <div className='w-100 ' >
                    <h6 >Old Password</h6>
                    <input className='w-100' onChange={changehandle} name="oldPassword" type='showPassword' />
                </div>
                <div className='w-100 '>
                    <h6>New Password</h6>
                    <input className='w-100' onChange={changehandle} name="newPassword" type='showPassword' />
                </div>
                <div className='w-100  ' >
                    <h6>Confirm Password</h6>
                    <input className='w-100' onChange={changehandle} name="confirmNewPassword" type='showPassword' />
                    <button className='btn btn-success w-100 mt-5' type='submit' >Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword