import React from 'react'
import { useLocation } from 'react-router-dom'

const PropertyDetails = () => {
    const location = useLocation().state
    const property_number = location.full_address.split(",")[location.full_address.split(",").length - 5]
    const street_name = location.full_address.split(",")[location.full_address.split(",").length - 4]

    return (
        <div style={{ transition: "all 0.3s ease-in-out" }}>
            <div className='property h-auto d-flex justify-content-center pt-3' style={{ transition: "all 0.3s ease-in-out", }} >
                <div className='property-card w-100 mb-4 flex-column ' style={{ maxWidth: "800px", height: "80vh", overflowY: 'scroll' }}>

                    <h3 >Proprty Details :</h3>
                    <div className='property-card w-100 flex-wrap flex-column' style={{ backgroundColor: '#E2E5E6' }}>
                        <p><b> Property number :</b>{location?.house_number} </p>
                        <p><b> Property status :</b> {location?.status} </p>
                        <p><b> Property type :</b> {location?.property_type} </p>
                        <p><b> Property flor :</b> {location?.flor} </p>
                        <p><b> Property street :</b> {property_number || street_name ? <span>{property_number}, {street_name}</span> : 'N/A'} </p>
                        <p><b> Property full address :</b> {location?.full_address} </p>

                    </div>
                    <div className='property-card  w-100 mt-5 ' style={{ backgroundColor: '#E2E5E6' }}>
                        <div className='row  text-start'>
                            <p className='col-12'><b> Name :</b> {location?.u_name} </p>
                            <p className='col-12'><b> Property status :</b> {location?.status} </p>
                            <p className='col-12'><b> Property type :</b> {location?.property_type} </p>
                            <p className='col-12'><b> Property flor :</b> {location?.flor} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyDetails