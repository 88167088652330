import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetReportDetailsQuery } from '../ReportSlice';
import sampleImg from "../../../images/giphy.webp"
import Spinner from '../../../Components/Spinner/SpinnerForTable';
import moment from 'moment';

const getImageFile = async (imageUrl) => {
    try {
        const res = await fetch(imageUrl);
        const blob = await res.blob();

        return new File([blob], "image", { type: blob.type });
    } catch (error) {
        console.log("Error fetching original image:", error);
    }
};

const ReportDetails = () => {
    const { id } = useParams();
    const [aiImageUrls, setAiImageUrls] = useState([]);
    const [size, setSize] = useState()
    const [rotationState, setRotationState] = useState(0)
    const [rotatecount, setrotatecount] = useState()

    const getAIImage = async (imageFormData) => {
        try {
            imageFormData.append("model_type", false)
            const res = await fetch("https://imagerating.ecowrap.in/inference", {
                method: "POST",
                body: imageFormData,
            });

            const file = await res.blob();

            return file;
        } catch (error) {
            console.log("error in fetching ai image", error);
        }
    }

    const getAiCountedImageUrl = async (imageUrl) => {
        try {
            const data = new FormData();

            // Get original image.
            const imageFile = await getImageFile(imageUrl);
            data.append(imageFile.name, imageFile);

            // Get AI image.
            const aiImageBlob = await getAIImage(data);

            // convert blob to url.
            const aiImageUrl = URL.createObjectURL(aiImageBlob);

            return aiImageUrl
        } catch (err) {
            console.error("Error in handleSubmit:", err);
        }
    };

    const {
        data: reportDetails,
        isLoading,
        error,
    } = useGetReportDetailsQuery({ id })


    useEffect(() => {
        const imagesArr = reportDetails?.images_names?.split(",");
        if (reportDetails?.images_names !== null && imagesArr) {
            (async () => {
                for (let i = 0; i < imagesArr.length; i++) {
                    setAiImageUrls(prev => {
                        prev[i] = sampleImg;
                        return [...prev];
                    });
                }

                for (let i = 0; i < imagesArr.length; i++) {
                    const e = imagesArr[i];
                    const url = await getAiCountedImageUrl(`https://apis.ecowrap.in/${e}`);
                    setAiImageUrls(prev => {
                        prev[i] = url;
                        return [...prev];
                    });
                }

            })()
        }
    }, [reportDetails]);

    const Zoomout_image = () => {
        if (size === true) {
            const allImages = document.querySelectorAll('.mix-empty-img');
            allImages.forEach((img) => {
                img.classList.remove('mix-empty-img-enlar');
                img.style.transform = 'none';
            });
            setSize(false)
        }
    }

    const imgZoomToggle = (e) => {
        const clickedElement = e.currentTarget;
        const isAlreadyEnlarged = clickedElement.classList.contains('mix-empty-img-enlar');

        setSize(true)

        if (!isAlreadyEnlarged) {
            clickedElement.classList.add('mix-empty-img-enlar');
            const rotation = rotationState[clickedElement.id] || 0;
            clickedElement.style.transform = `rotate(${rotation}deg) scale(2)`;
        } else {
            setRotationState({ [clickedElement.id]: 0 })
            setrotatecount(0)
        }
        Zoomout_image()
    };

    const rotateImage = (e, imgId) => {

        const image = document.getElementById(imgId);
        if (image && image.classList.contains('mix-empty-img-enlar')) {
            let currentRotation = 0
            currentRotation = rotationState[imgId] || 0;
            const newRotation = currentRotation + 90;

            setRotationState(prevState => ({
                ...prevState,
                [imgId]: newRotation
            }));

            if (rotatecount % 2 === 0) {
                image.style.transform = `rotate(${newRotation}deg) scale(2,4)`;
            } else {
                image.style.transform = `rotate(${newRotation}deg) scale(2)`;
            }

            setrotatecount(rotatecount + 1)
        }
    };

    const rating = reportDetails?.rating_of_image?.split(",")
    const category = reportDetails?.identified_category_of_images?.split("}")

    return (
        < >
            {error && <div>Someting Went Wrong</div>}
            {isLoading && <Spinner />}
            {reportDetails && <div className='report d-flex overflow-scroll  flex-column gap-5 align-items-center   pt-5'>

                <div className="report-card flex-column gap-4 h-auto">
                    <h4>Property Details:</h4>
                    <div className='row'>
                        <p className='col-12'><strong>House Number : </strong>{reportDetails?.house_number}</p>
                        <p className='col-12'><strong>Full Address : </strong>{reportDetails?.full_address}</p>
                        <p className='col-12'><strong>Street : </strong>{reportDetails?.street}</p>
                        <p className='col-6'><strong>Total Member : </strong>{reportDetails?.total_members === -1 ? '0' : reportDetails?.total_members}</p>
                        <p className='col-6'><strong>Collection Date : </strong>{moment(reportDetails?.collected_at).format("DD/MM/YYYY")}</p>
                    </div>
                </div>
                <div className="report-card h-50 justify-content-center flex-wrap ">
                    {aiImageUrls?.map((image, e) => {
                        return <div key={e} className="col-md-4 mb-4">
                            <div className="d-flex justify-content-center flex-column" style={{ objectFit: 'contain' }}>
                                <img onClick={imgZoomToggle} id={`docImg${image + 1}`} className="mix-empty-img rounded" style={{ height: '200px', width: '200px' }} src={image} alt={image} />
                                {rating && <p>Rating :{rating[e]} {String.fromCharCode(9733)}</p>}
                                {category && <p>Category :{category[e]?.split(/[{},"]/)}</p>}
                                <button className="rotate-button btn-dark" onClick={(event) => rotateImage(event, `docImg${image + 1}`)} > Rotate Image </button>
                            </div>
                        </div>
                    })}
                </div>

            </div>}
        </>
    )
}

export default ReportDetails