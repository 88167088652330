import React, { useState } from 'react'
import { useGetCollectionQuery } from './CollectionSlice'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Components/Spinner/SpinnerForTable'
import Pagination from '../../Components/utils/Pagination'
import { TableHead } from '../../Components/utils/TableFormat'
const CollectionList = () => {
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const thead = ['Collection Date', 'Total Collection'];

    const {
        data: collection,
        isLoading
    } = useGetCollectionQuery({ page })

    return (
        <>
            {isLoading && <Spinner />}
            {collection &&
                <div className='collection d-flex justify-content-center pt-3'>
                    <div className="collection-card w-100 h-75">
                        <table className="table table-borderless h-auto table-striped table-hover">
                            <TableHead thead={thead} />
                            <tbody>
                                {collection?.entities?.map((e, index) => {
                                    return (
                                        <>
                                            <tr key={index} onClick={() => navigate('/collectiondetails', { state: e })} className="listData text-left fw-normal" >
                                                <td>{e.collection_date}</td>
                                                <td>{e.total}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination page={page} totalPages={collection?.totalPages || 0} setPage={setPage} />
                </div>}
        </>
    )
}

export default CollectionList