import React from 'react';
import { useGetProfileQuery } from './ProfileSlice';
import Spinner from '../Components/Spinner/SpinnerForTable';

function Profile() {

    const {
        data: profile,
        isLoading,
        error,
    } = useGetProfileQuery('getProfile')

    const profileData = profile?.entities[profile?.ids]

    return (
        <>
            {error && <div>Someting Went Wrong</div>}
            {isLoading && <Spinner />}
            {profile &&
                <div className='profile  d-flex justify-content-center pt-4'>
                    <div className="profile-card"  >
                        <div className="profile-details text-start">
                            <p><strong>Name:</strong> {profileData?.ca_name}</p>
                            <p><strong>Number:</strong>{profileData?.ca_number}</p>
                            <p><strong>Email:</strong>{profileData?.ca_email}</p>
                        </div>
                        <hr className='line-profile' style={{ width: '2px', height: '120px' }} />
                        <div className="profile-roles">
                            <p><strong>Position:</strong>{profileData?.ca_position}</p>
                            <p><strong>Role:</strong>{profileData?.ca_type} Admin</p>
                            <p><strong>Role:</strong>{profileData?.district_name}</p>
                            <p><strong>Role:</strong>{profileData?.community_name}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Profile;