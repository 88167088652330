import { apiSlice } from "../../Features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProperties: builder.query({
            query: ({ page }) => `/survey/property?page=${page}`,
            transformResponse: (responseData) => {
                const { pagination, result } = responseData?.data[0] || {};
                return { pagination, entities: result };
            },
            providesTags: (results, error, arg) => {
                if (error) {
                    return [];
                }
                if (results?.ids) {
                    return [
                        { type: 'Properties', id: "List" },
                        ...results.ids.map(id => ({ type: 'Properties', id })),
                    ];
                }
                return [];
            },
        }),
    }),
});

export const {
    useGetPropertiesQuery,
} = extendedApiSlice;
