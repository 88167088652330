import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './Authentication/Login';
import Navbar from './Navbar/Navbar';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LiveMap from './pages/Driver/liveDriver';
import CollectionMap from './pages/Collection/CollectionDetails/CollectionMap';
import CollectionDetails from './pages/Collection/CollectionDetails/CollectionDetails';
import CollectionList from './pages/Collection/CollectionList';
import PropertyDetails from './pages/Property/PropertyDetails/PropertyDetails';
import PropertiesList from './pages/Property/PropertiesList';
import ChangePassword from './pages/Password/ChangePassword';
import ReportList from './pages/Report/ReportList';
import ReportDetails from './pages/Report/ReportDetails/ReportDetails';
import Profile from './Profile/Profile';
import Sidebar2 from './Navbar/Sidebar2';
import Test from './Test';
import { logout, tokenNotFound } from './Authentication/authSlice';
import { useGetPropertiesQuery } from './pages/Property/PropertiesSlice';

const App = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);

  const { token, isAuthenticated } = useSelector(state => state.auth)
  const { error } = useGetPropertiesQuery('getProperties')

  useEffect(() => {
    if (isAuthenticated === null && token === null) {
      dispatch(tokenNotFound());
    }
    else if (isAuthenticated === false && location.pathname !== '/login') {
      navigate('/login');
    } else if (isAuthenticated && location.pathname === '/login') {
      navigate('/')
    }

    if (location.pathname === '/') {
      navigate('/properties')
    }

    if (token !== null && error?.status === 401) {
      dispatch(logout())
    }

  }, [isAuthenticated, token, location, error, dispatch, navigate]);


  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        {isAuthenticated && (
          <>
            <Route
              path="/*"
              element={
                <div className='container-fluid col-' style={{ backgroundColor: '#DADFE0' }}>
                  <div className='row app-sidebar '>
                    <Navbar setSidebar={setSidebar} />
                    <div className={` ${isExpanded ? 'sidebar-width1' : 'sidebar-width2'} px-0`} style={{ transition: 'all 0.3s ease-in-out', }} >

                      <div>
                        <Sidebar2 isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                      </div>
                    </div>
                    <div onClick={() => setSidebar(false)} style={{ transition: 'all 0.3s ease-in-out', }} className={`main ${isExpanded ? 'main-width1' : 'main-width2'}  px-0`}>
                      <Routes>
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/test" element={<Test />} />
                        <Route path="/sidebar2" element={<Sidebar2 />} />
                        <Route path="/password" element={<ChangePassword />} />
                        <Route path="/properties" element={<PropertiesList />} />
                        <Route path="/propertydetails" element={<PropertyDetails />} />
                        <Route path="/collection" element={<CollectionList />} />
                        <Route path="/collectiondetails" element={<CollectionDetails />} />
                        <Route path="/collectionmap" element={<CollectionMap />} />
                        <Route path="/driver" element={<LiveMap />} />
                        <Route path="/report" element={<ReportList />} />
                        <Route path="/report/:id" element={<ReportDetails />} />
                      </Routes>
                    </div>
                  </div>
                </div>
              }
            />
          </>
        )}
      </Routes>
    </>
  )
}

export default App 