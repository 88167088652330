import { apiSlice } from "../../Features/api/apiSlice"


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        changePassword: builder.mutation({
            query: body => ({
                url: '/authentication/change-password',
                method: 'POST',
                body: body
            }),
        }),
    })
})

export const {
    useChangePasswordMutation,
} = extendedApiSlice

