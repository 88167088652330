import React from 'react'
export const TableHead = ({ thead }) => {
    return (

        <thead className='bg-success text-light'>
            <tr>
                {thead.map((e) => <td key={e}><b>{e}</b></td>)}
            </tr>
        </thead>

    )
}
