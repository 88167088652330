import React, { useEffect, useState } from 'react';
import Maps from '../../Components/utils/GoogleMaps';
import { io } from 'socket.io-client';

const DEFAULT_CENTER = { lat: 26.8954906, lng: 75.839768 };
const DEFAULT_ZOOM = 15;
const MAP_API_KEY = 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8';
const SOCKET_URL = 'https://apis.ecowrap.in/driverLocation';

const LiveMap = () => {

    const [locations, setLocations] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [areaBoundries, setAreaBoundries] = useState([]);
    const [areaPaths, setAreaPaths] = useState([]);

    const getTruckIconUrl = ({ status }) => {
        const color = status === 'Disconnected' ? 'gray' : '#4CAF50'; // Gray for 'Disconnected', green for others
        const iconSvg = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200" width="100" height="200" transform="rotate(90deg  )">
                <rect x="10" y="40" width="80" height="120" fill="${color}" stroke="black" stroke-width="2" />
                <rect x="20" y="10" width="60" height="30" fill="#333" stroke="black" stroke-width="2"/>
                <circle cx="20" cy="170" r="10" fill="black" />
                <circle cx="80" cy="170" r="10" fill="black" />
                <circle cx="20" cy="30" r="10" fill="black" />
                <circle cx="80" cy="30" r="10" fill="black" />
                <rect x="15" y="50" width="70" height="20" fill="#8BC34A" stroke="black" stroke-width="1" />
                <rect x="15" y="90" width="70" height="20" fill="#8BC34A" stroke="black" stroke-width="1" />
                <rect x="15" y="130" width="70" height="20" fill="#8BC34A" stroke="black" stroke-width="1" />
            </svg>`;
        return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`;
    };

    // UseEffect to get area, boundary & path data
    useEffect(() => {
        const getData = async () => {
            try {
                let result = await fetch("https://apis.ecowrap.in/b2g/community-admin/liveDriver/a0f5", {
                    headers: {
                        authorization: `bearer ${(localStorage.getItem("token"))}`
                    }
                });
                result = await result.json();

                if (result.statusCode === 200) {
                    setAreaBoundries(result?.data[0]?.areaBoundaries || []);
                    setAreaPaths(result?.data[0]?.pathData || []);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    // UseEffect to Set area, boundary & path data on MapView
    useEffect(() => {

        const polygons = [];
        const polylines = [];

        if (mapInstance && googleMaps) {
            // Create and store polygons
            areaBoundries.map((item) => {
                const polygonCoordinates = item.boundry[0].map((location) => ({
                    lat: location.x,
                    lng: location.y
                }));

                const polygon = new googleMaps.Polygon({
                    paths: polygonCoordinates,
                    strokeColor: '#808080',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                    fillColor: '#808080',
                    fillOpacity: 0.0,
                });

                polygon.setMap(mapInstance);
                polygons.push(polygon);

                return () => {
                    polygon.setMap(null);
                };
            });

            // Create and store polylines
            areaPaths.map((item) => {
                const polylineCoordinates = item.map((cord) => ({
                    lat: cord?.location?.x,
                    lng: cord?.location?.y
                }));

                const polyline = new googleMaps.Polyline({
                    path: polylineCoordinates,
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });

                polyline.setMap(mapInstance);
                polylines.push(polyline);

                return () => {
                    polyline.setMap(null);
                };
            });
        }

        // Cleanup function to remove polygons and polylines
        return () => {
            polygons.forEach((polygon) => {
                polygon.setMap(null);
            });

            polylines.forEach((polyline) => {
                polyline.setMap(null);
            });
        };
    }, [mapInstance, googleMaps, areaBoundries, areaPaths]);


    useEffect(() => {
        const socket = io(SOCKET_URL);

        socket.on('updateAdmin', (data) => {
            try {
                const locationsData = data.map((item) => {
                    const { status, id, name, assignment_id, lat, lng } = item;
                    if (lat !== 0 && lng !== 0) return { status, id, name, assignment_id, lat, lng };
                    return null;
                }).filter((item) => item !== null);
                setLocations(locationsData);

            } catch (error) {
                console.error('Error processing location data:', error);
            }
        });
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect((location) => {
        if (mapInstance && googleMaps && locations.length > 0) {
            const newMarkers = markers;
            locations.forEach(loc => {
                if (loc.lat === 0 || loc.lng === 0 || !loc.lat || !loc.lng) return null;
                let existingMarker = newMarkers.find(marker => marker.id === loc.id);
                if (existingMarker) {
                    existingMarker.setPosition(new googleMaps.LatLng(loc.lat, loc.lng));
                    existingMarker.icon.url = getTruckIconUrl({ status: loc.status });
                } else {
                    // const rotatedIcon = rotateImage(rotationAngle);
                    const marker = new googleMaps.Marker({
                        position: new googleMaps.LatLng(loc.lat, loc.lng),
                        // icon: rotatedIcon,
                        map: mapInstance,
                        icon: {
                            url: getTruckIconUrl({ status: loc.status }),
                            scaledSize: new googleMaps.Size(40, 40)
                        }
                    });
                    marker.id = loc.id;
                    newMarkers.push(marker);
                }
            });

            markers.forEach((marker, index) => {
                const loc = locations.find(loc => loc.id === marker.id);
                if (!loc) {
                    marker.setMap(null);
                    newMarkers.splice(index, 1);
                }
            });
            setMarkers(newMarkers);
        } else if (locations.length === 0) {
            markers.forEach(marker => marker.setMap(null));
            setMarkers([]);
        }

    }, [locations, mapInstance, googleMaps]);

    return (
        <div className="container-fluid position-relative" >
            <div className="row mt-4">
                <div className="col-md-12" style={{ height: '78vh', width: '100%', zIndex: '2', position: 'relative' }}>
                    {areaBoundries && areaPaths &&
                        <Maps MAP_API_KEY={MAP_API_KEY} DEFAULT_CENTER={DEFAULT_CENTER} DEFAULT_ZOOM={DEFAULT_ZOOM} mapCenter={''} setGoogleMaps={setGoogleMaps} setMapInstance={setMapInstance} />
                    }
                </div>
            </div>
        </div>
    );
};

export default LiveMap;
