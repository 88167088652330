import React from 'react'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useGetCollectionDateQuery } from '../CollectionSlice';

const CollectionDetailsExcerpt = ({ id, date }) => {

    const navigate = useNavigate()

    const { collection } = useGetCollectionDateQuery({ date }, {
        selectFromResult: ({ data }) => ({
            collection: data?.entities[id]
        }),
    }) 
    return (
        <div className=' collheight my-3' style={{ height: 'auto' }}  >
            <div className='row text-start px-3 '>
                <div className='col-md-12 text-end'><button onClick={() => { navigate('/collectionmap', { state: collection }) }} className='  btn btn-success'>Map</button> </div>
                <div className='col-md-6'>Start: {moment(collection.start_time).format(" hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}   </div>
                <div className='col-md-6'>End: {moment(collection.end_time).format(" hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })} </div>
                <div className='col-md-6'>Status: {collection.status}  </div>
                <div className='col-md-6'>Category:  {collection.category} </div>
                <div className='col-md-6'>Area: {collection.area_name} </div>
                <div className='col-md-6'>Driver Name: {collection.p_name} </div>
                <div className='col-md-6'>Driver Number:  {collection.p_number} </div>
                <div className='col-md-6'>Helper Name:  {collection.helper_name} </div>
                <div className='col-md-6'>Vehicle Number:  {collection.vehicle_number} </div>

            </div>

        </div >
    )
}

export default CollectionDetailsExcerpt