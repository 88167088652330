import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
// import '../driverLive/liveDriver.css';
import layers from '../../images/layers.png'

const Maps = ({ MAP_API_KEY, DEFAULT_CENTER, DEFAULT_ZOOM, mapCenter, setGoogleMaps, setMapInstance, }) => {

    const [mapType, setMapType] = useState("ROADMAP");
    const mapView = () => {
        setMapType((e) => (e === "ROADMAP" ? "SATELLITE" : "ROADMAP"));
    };

    const createMapOptions = (maps) => {
        return { mapTypeId: maps.MapTypeId[mapType] }
    }

    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: MAP_API_KEY }}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                yesIWantToUseGoogleMapApiInternals
                center={mapCenter}
                zoom={15}
                onGoogleApiLoaded={({ map, maps }) => {
                    setMapInstance(map);
                    setGoogleMaps(maps);
                }}
                options={createMapOptions}
            >
                <div style={{
                    position: 'fixed',
                    top: `150px`,
                    left: `-600px`,
                }}>
                    {<img style={{ width: "50px", height: "50px" }} src={layers} alt='Satellite' onClick={mapView} />}
                </div>
            </GoogleMapReact >
        </>
    );
};

export default Maps;
