import React from 'react'

const Test = () => {
    return (
        <div className='d-flex  justify-content-center h-100 w-100'>
            <input onChange={() => { }} type='text' />

            <button className='btn btn-secondary'> hello </button>
        </div>
    )
}

export default Test