import React, { useEffect, useState } from 'react'
import { useGetReportQuery } from './ReportSlice'
import Spinner from '../../Components/Spinner/SpinnerForTable';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../Components/utils/Pagination';
import { TableHead } from '../../Components/utils/TableFormat';

const ReportList = () => {

    const [filterSearch, setFilterSearch] = useState({ date: '', name: '' })
    const [filterData, setFilterData] = useState({ date: '', name: '' })
    const navigate = useNavigate();
    const thead = ['#', 'Property', 'Area', 'Waste Collected', 'Waste Category', 'Segreated', 'Remark', 'Reason'];

    const [page, setPage] = useState(() => {
        const savedPage = sessionStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })

    useEffect(() => {
        sessionStorage.setItem('dailyReportPage', page);
    }, [page]);

    const chnagehandle = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFilterSearch({ ...filterSearch, [name]: value })
    }

    const searchClick = () => {
        setFilterData(filterSearch)
        setPage(1)
    }

    const resetClick = () => {
        setFilterData({ date: '', name: '' })
        setFilterSearch({ date: '', name: '' })
        setPage(1)
    }

    const {
        data: reports,
        isLoading,
        error
    } = useGetReportQuery({ filterData, page })

    return (
        <>
            {error && <div>Someting Went Wrong</div>}
            {isLoading && <Spinner />}
            {reports &&
                <>
                    <div className='m-0 px-2 d-flex justify-content-center gap-3 flex-wrap py-4' style={{ backgroundColor: '#E2E5E6' }} >
                        <input onChange={chnagehandle} value={filterSearch.date} placeholder='date' name="date" className="form-control" style={{ width: '15%', minWidth: '100px' }} type="date" />
                        <input onChange={chnagehandle} value={filterSearch.name} placeholder='Searh Property' name='name' className="form-control" style={{ width: '15%', minWidth: '100px' }} type="text" />
                        <button onClick={searchClick} className=' mx-2 btn btn-success'>Submit</button>
                        <button onClick={resetClick} className='btn btn-secondary'>Reset</button>
                    </div>
                    <div className='collection overflow-scroll d-flex justify-content-center flex-wrap flex-row gap-2 pb-3'>
                        <table className='table full-size   w-75 mb-5 '>
                            <TableHead thead={thead} />
                            <tbody>
                                {
                                    reports?.entities?.map((e, i) => {
                                        const property_number = e.full_address.split(",")[e.full_address.split(",").length - 5]
                                        const street_name = e.full_address.split(",")[e.full_address.split(",").length - 4]
                                        return (
                                            < tr key={i} onClick={() => { navigate(`/report/${e.wpp_id}`) }} >
                                                <td>{i + 1 + (page - 1) * 10}  </td>
                                                <td>{e.house_number} </td>
                                                <td>{property_number || street_name ? <span>{property_number} , {street_name}</span> : 'N/A'}</td>
                                                <td>{e.waste_provided === 1 ? 'Yes' : 'No'} </td>
                                                <td>{(e.waste_category) === '[]' ? 'N/A' : JSON.parse(e.waste_category).map((e) => `${e}      `)} </td>
                                                <td>{e.is_segregate === 1 ? 'Yes' : 'No'} </td>
                                                <td>{e.remark ? e.remark : 'N/A'} </td>
                                                <td>{e.reason ? e.reason : 'N/A'} </td>
                                            </ tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {reports?.entities?.map((e, i) => {
                            const property_number = e.full_address.split(",")[e.full_address.split(",").length - 5]
                            const street_name = e.full_address.split(",")[e.full_address.split(",").length - 4]
                            return (
                                <div key={e?.wpp_id} className="mob-size report-card flex-column gap-4 h-auto" onClick={() => { navigate(`/report/${e.wpp_id}`) }}>
                                    <h4>{i + 1 + (page - 1) * 10}.</h4>
                                    <div className='row'>
                                        <p className='col-12'><strong>House Number : </strong>{e.house_number}</p>
                                        <p className='col-12'><strong>Street : </strong>{property_number || street_name ? <span>{property_number} , {street_name}</span> : 'N/A'}  </p>
                                        <p className='col-12'><strong>Waste Collected : </strong>
                                            {e.waste_provided === 1 ? 'Yes' : 'No'}  </p>
                                        <p className='col-12'><strong>Segreated : </strong>
                                            {JSON.parse(e.waste_category).map((e) => `${e}      `)}  </p>
                                        <p className='col-6'><strong>Remark : </strong>
                                            {e.remark ? e.remark : 'N/A'} </p>
                                        <p className='col-6'><strong>Reason : </strong>
                                            {e.reason ? e.reason : 'N/A'} </p>
                                    </div>
                                </div>
                            )
                        })}

                        <Pagination page={page} totalPages={reports?.totalPage || 0} setPage={setPage} />

                    </div>
                </>
            }

        </>
    )
}

export default ReportList