import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: !!localStorage.getItem('token'),
    loginError: null,
    loginStatus: null,
    error: null,
};

export const login = createAsyncThunk(
    'auth/login',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await fetch(`https://apis.ecowrap.in/b2g/community-admin/authentication`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();
            if (!response.ok || response.status === 203) {
                throw new Error(data.message || 'Login failed');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.token = null;
            state.isAuthenticated = false;
            state.loginError = null;
            state.loginStatus = null;
            localStorage.removeItem('token');
        },
        checkError(state, action) {
            state.error = 401
        }, tokenNotFound(state, action) {
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loginStatus = 'pending';
                state.loginError = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                localStorage.setItem('token', action.payload.data[0]);
                state.loginStatus = 'done'
                state.loginError = null;
                state.isAuthenticated = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.loginStatus = 'failed';
                state.loginError = action.payload || 'Login failed';
            });
    },
});

export const { logout, checkError, tokenNotFound } = authSlice.actions;
export default authSlice.reducer; 